import React from "react"
import CaptureLeads from "../components/home/CaptureLeads"
import GetFeedback from "../components/home/GetFeedback/GetFeedback"
import Hero from "../components/home/Hero"
import Integrations from "../components/home/Integrations/Integrations"
import References from "../components/home/References"
import SetupLumis from "../components/home/SetupLumis/SetupLumis"
import Layout from "../components/Layout"
import SEO from "../components/SEO"

const IndexPage = () => (
  <>
    {/* <Notification
      actionText="Listen Now"
      actionLink="https://medium.com/lumis-ai/wilde-möhre-alexander-dettke-de-f5c6dc2fd514"
    >
      Podcast <strong>About Events</strong> with Alexander Dettke (Wilde Möhre)
    </Notification> */}
    <Layout>
      <SEO title="Sell more tickets" />
      <Hero />
      <References />
      <SetupLumis />
      <CaptureLeads />
      <GetFeedback />
      <Integrations />
    </Layout>
  </>
)

export default IndexPage
