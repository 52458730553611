import React from "react"
import { Badge, Col, Container, Row } from "react-bootstrap"
import Facebook from "../../../assets/svg/logos/facebook.svg"
import GoogleAnalytics from "../../../assets/svg/logos/googleanalytics.svg"
import Mailchimp from "../../../assets/svg/logos/mailchimp.svg"
import Pinterest from "../../../assets/svg/logos/pinterest.svg"
import Slack from "../../../assets/svg/logos/slack.svg"
import Snapchat from "../../../assets/svg/logos/snapchat.svg"

const Integration = ({ logo, title, description }) => (
  <Col md="6" lg="4" className="text-center">
    <div className="icon icon-lg mb-4">{logo}</div>
    <h3 className="font-weight-bold">{title}</h3>
    <p className="text-muted mb-8">{description}</p>
  </Col>
)

const Integrations = () => (
  <section className="pt-8 pt-md-11 pb-8">
    <Container>
      <Row className="justify-content-center">
        <Col md="10" lg="8" className="text-center">
          <Badge variant="primary-soft" className="mb-3" pill>
            <span className="h6 text-uppercase">Integrations</span>
          </Badge>
          <h2 className="font-weight-bold">Works with your favorite apps.</h2>
        </Col>
      </Row>
      <Row className="mt-5">
        <Integration
          logo={<Slack />}
          title="Slack"
          description="Notifications directly in your channel."
        />
        <Integration
          logo={<Mailchimp />}
          title="Mailchimp"
          description="Export / Import your email list."
        />
        <Integration
          logo={<GoogleAnalytics />}
          title="Google Analytics"
          description="Track who visited your site."
        />
        <Integration
          logo={<Facebook />}
          title="Facebook Pixel"
          description="Track who visited your site."
        />
        <Integration
          logo={<Pinterest />}
          title="Pinterest Pixel"
          description="Track who visited your site."
        />
        <Integration
          logo={<Snapchat />}
          title="Snapchat Pixel"
          description="Track who visited your site."
        />
      </Row>
    </Container>
  </section>
)

export default Integrations
