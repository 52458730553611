import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import dasbhoardImage from "../../../assets/images/screenshots/compare.png"
import GdprIcon from "../../../assets/svg/icons/gdpr.svg"
import ShapeAngle from "../../../assets/svg/shapes/angles/angle-bottom.svg"

const CaptureLeads = () => (
  <section className="position-relative pt-8 pt-md-10 pb-8 pb-md-12">
    <Container>
      <Row className="align-items-center">
        <Col md="6" lg="7" className="order-md-2">
          <div className="w-md-150 w-lg-130 position-relative mb-8" data-aos="fade-up">
            <img
              src={dasbhoardImage}
              className="position-relative screenshot img-fluid"
              alt="Lumis Dashboard"
            />
          </div>
        </Col>
        <Col md="6" lg="5" className="order-md-1">
          <h2>
            Smart Lead Capture.
            <br />
            <span className="text-primary">Sell more tickets.</span>
          </h2>

          <p className="font-size-lg text-gray-700 mb-6">
            Our AI captures emails early. Then it reminds your customers with the right email at the
            right time.
          </p>

          <div className="d-flex bg-light p-3 pt-5">
            <div className="icon text-primary">
              <GdprIcon />
            </div>

            <div className="ml-5">
              <h4 className="mb-1">GDPR Compliant Data Capture</h4>

              <p className="text-muted">
                Based in Germany and 100% committed to make every data capture compliant.
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
    <div className="shape shape-bottom shape-fluid-x svg-shim" style={{ color: "#f9fbfd" }}>
      <ShapeAngle />
    </div>
  </section>
)

export default CaptureLeads
