import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import dasbhoardImage from "../../../assets/videos/examples.mp4"
import Icon from "../../Icon"

const SetupLumis = () => (
  <section className="pt-8 pb-0 py-md-12">
    <Container>
      <Row className="align-items-center">
        <Col md="6">
          <div className="img-skewed img-skewed-right mb-10 mb-md-0" data-aos="fade-up">
            <video
              loop
              autoPlay
              playsInline
              muted
              width="400"
              className="img-fluid"
              alt="Lumis Dashboard"
              data-aos="img-skewed-item-right"
              style={{
                boxshadow:
                  "0 0 2px 1px rgba(0,0,0,.05), 30px 40px 60px rgba(0,0,0,.05), 5px 5px 20px rgba(0,0,0,.05)",
                borderRadius: 12,
              }}
            >
              <source src={dasbhoardImage} />
            </video>
          </div>
        </Col>
        <Col md="6" lg="5">
          <h2>
            Premium Ticketing.
            <br />
            <span className="text-success">Beautiful Event Pages.</span>
          </h2>

          <Row>
            <Col lg="6">
              <div className="d-flex">
                <div className="badge badge-rounded-circle badge-white text-dark mt-1 mr-1">
                  <i className="fe fe-check"></i>
                  <Icon icon="arrow-right" size="sm" />
                </div>

                <p className="text-dark">Easy Setup</p>
              </div>

              <div className="d-flex">
                <div className="badge badge-rounded-circle badge-white text-dark mt-1 mr-1">
                  <i className="fe fe-check"></i>
                  <Icon icon="arrow-right" size="sm" />
                </div>

                <p className="text-dark mb-lg-0">Custom Design </p>
              </div>
            </Col>
            <div className="col-12 col-lg-6">
              <div className="d-flex">
                <span className="badge badge-rounded-circle badge-white text-dark mt-1 mr-1">
                  <i className="fe fe-check"></i>
                  <Icon icon="arrow-right" size="sm" />
                </span>

                <p className="text-dark">Simple interface</p>
              </div>

              <div className="d-flex">
                <div className="badge badge-rounded-circle badge-white text-dark mr-1 mr-1">
                  <i className="fe fe-check"></i>
                  <Icon icon="arrow-right" size="sm" />
                </div>

                <p className="text-dark mb-0">
                  <strong>100% your brand</strong>
                </p>
              </div>
            </div>
          </Row>
        </Col>
      </Row>
    </Container>
  </section>
)

export default SetupLumis
