import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import ordersImage from "../../../assets/images/screenshots/profits-3.gif"
import ShapeCurve from "../../../assets/svg/shapes/blurs/blur-1.svg"
import Icon from "../../Icon"
import "./scss/index.scss"

const Hero = () => {
  const handleClick = () => {
    window.analytics.track("CTA Button Clicked")
  }
  return (
    <section className="hero position-relative pt-4 pt-lg-10 pb-11 pb-md-12">
      <div className="shape shape-fluid-x shape-blur-1 svg-shim text-gray-200">
        <ShapeCurve />
      </div>

      <Container>
        <Row className="align-items-center">
          <Col md="6" className="order-md-2">
            <div className="img-skewed img-skewed-left mb-8 mb-md-0">
              <img
                src={ordersImage}
                className="screenshot img-fluid mw-md-130 img-skewed-item"
                alt="Lumis Dashboard"
                data-aos="img-skewed-item-left"
              />
            </div>
          </Col>
          <Col md="6" className="order-md-1">
            <h1 className="display-3">
              Grow your <br />
              event business.
            </h1>
            <p className="lead text-muted mb-6 mb-md-8">Spend less hours, sell more tickets.</p>
            <Link onClick={handleClick} to="start" className="btn btn-primary btn-lg lift">
              Start Selling Tickets
              <Icon icon="arrow-right" size="sm" className="ml-2" />
            </Link>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Hero
