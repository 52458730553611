import React from "react"
import { Button, Col, Container, Row } from "react-bootstrap"
import dashboardImage from "../../../assets/images/screenshots/feedback-2.png"

const GetFeedback = () => (
  <section className="pt-8 pb-8 pb-0 py-md-12 bg-light">
    <Container>
      <Row className="align-items-center">
        <Col md="6">
          <div className="img-skewed mb-10 mb-md-0" data-aos="fade-up">
            <img
              src={dashboardImage}
              className="img-fluid img-skewed-item mw-md-130"
              alt="Lumis Dashboard"
              width="100%"
            />
          </div>
        </Col>
        <Col md="6" lg="5">
          <h2>
            Smart Feedback.
            <br />
            <span className="text-success">Understand your visitors.</span>
          </h2>

          <p className="font-size-lg text-gray-700 mb-6">
            We send visitors a survey after a ticket purchase and after the event. You'll understand
            your visitors like never before.
          </p>

          <Button variant="success" className="mt-3 lift" href="/start">
            Start selling tickets
          </Button>
        </Col>
      </Row>
    </Container>
  </section>
)

export default GetFeedback
